import React, { useEffect } from 'react'
import Navbar from '../Includes/Navbar'
import Footer from '../Includes/Footer'
import { Link } from 'react-router-dom/dist';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GeneralService from '../../services/general.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ContactUsSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  subject: Yup.string().required('Subject is required'),
  message: Yup.string().required('Message is required'),
});
export default function ContactUs() {
    const latitude = 32.0791;
    const longitude = 72.6711;
    const locationName = "329/A PUNJAB COLLEGE ROAD, Satellite Town, Sargodha, Punjab 40100";
    
    const encodedLocationName = encodeURIComponent(locationName);
    
    const googleMapsUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16559.662155007816!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391982b14c1f3c45%3A0x4711870a4d4643e4!2s329%2FA%20Punjab%20College%20Rd%2C%20Satellite%20Town%2C%20Sargodha%2C%20Punjab%2040100%2C%20Pakistan!5e0!3m2!1sen!2sus!4v1623395290880!5m2!1sen!2sus&q=${encodedLocationName}`;
 
    const {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      handleSubmit,
      isSubmitting,
      resetForm,
    } = useFormik({
      initialValues: {
        name: '',
        email: '',
        subject: '',
        message: '',
      },
      validationSchema: ContactUsSchema,
      onSubmit: async (values, actions) => {
        try {
          const response = await GeneralService.contactMessage(values);
          console.log('API response:', response);
          toast.success('Message successful Send!'); 
          resetForm();
          scrollToTop(); 
        } catch (error) {
          console.error('API error:', error);
          toast.error('Message Send failed. Please try again.');
        } finally {
          actions.setSubmitting(false);
        }
      },
    });

 
 
    const scrollToTop = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    };
  
    useEffect(() => {
      scrollToTop(); 
    }, []); 
  
 
 
    return (
   <>
   <Navbar/>
   <ToastContainer/>

   {/* <!-- Hero Start --> */}
    <div className="container-fluid bg-primary py-5 hero-header mb-5">
        <div className="row py-3">
            <div className="col-12 text-center">
                <h1 className="display-3 text-white animated zoomIn">Contact Us</h1>
                <Link to="/" className="h4 text-white">Home</Link>
                <span className="text-white px-2">∕</span>
                <a href="#" className="h4 text-white disabled" aria-disabled="true" style={{ pointerEvents: "none", textDecoration: "none", color: "gray", opacity: "0.5" }}>Contact Us</a>

            </div>
        </div>
    </div>
    {/* <!-- Hero End --> */}


    {/* <!-- Contact Start --> */}
    <div className="container-fluid py-5">
  <div className="container">
    <div className="row g-5">
      <div className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.1s">
        <div className="bg-light rounded h-100 p-5">
          <div className="section-title">
            <h5 className="position-relative d-inline-block text-primary text-uppercase">Contact Us</h5>
            <h1 className="display-6 mb-4">Feel Free To Contact Us</h1>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="bi bi-geo-alt fs-1 text-primary me-3"></i>
            <div className="text-start">
              <h5 className="mb-0">Our Office</h5>
              <span>329/A PUNJAB COLLEGE ROAD, Satellite Town, Sargodha, Punjab 40100</span>
            </div>
          </div>
          <div className="d-flex align-items-center mb-2">
            <i className="bi bi-envelope-open fs-1 text-primary me-3"></i>
            <div className="text-start">
              <h5 className="mb-0">Email Us</h5>
              <span>info@example.com</span>
            </div>
          </div>
          <div className="d-flex align-items-center">
            <i className="bi bi-phone-vibrate fs-1 text-primary me-3"></i>
            <div className="text-start">
              <h5 className="mb-0">Call Us</h5>
              <span>03009605574</span>
            </div>
          </div>
        </div>
      </div>
      <div className="col-xl-4 col-lg-6 wow slideInUp" data-wow-delay="0.3s">
      <form onSubmit={handleSubmit}>
  <div className="row g-3">
    <div className="col-12">
      <input
        type="text"
        id="name"
        name="name"
        className={`form-control border-0 bg-light px-4 ${touched.name && errors.name ? 'is-invalid' : ''}`}
        placeholder="Your Name"
        value={values.name}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ height: "55px" }}
        aria-label="Your Name"
      />
        {touched.name && errors.name && <div className="invalid-feedback">{errors.name}</div>}
    </div>
    <div className="col-12">
      <input
        type="email"
        id="email"
        name="email"
        className={`form-control border-0 bg-light px-4 ${touched.email && errors.email ? 'is-invalid' : ''}`}
        placeholder="Your Email"
        value={values.email}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ height: "55px" }}
        aria-label="Your Email"
      />
        {touched.email && errors.email && <div className="invalid-feedback">{errors.email}</div>}
    </div>
    <div className="col-12">
      <input
        type="text"
        id="subject"
        name="subject"
        className={`form-control border-0 bg-light px-4 ${touched.subject && errors.subject ? 'is-invalid' : ''}`}
        placeholder="Your Subject"
        value={values.subject}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ height: "55px" }}
        aria-label="Your Subject"
      />
        {touched.subject && errors.subject && <div className="invalid-feedback">{errors.subject}</div>}
    </div>
    <div className="col-12">
      <input
        type="text"
        id="message"
        name="message"
        className={`form-control border-0 bg-light px-4 ${touched.message && errors.message ? 'is-invalid' : ''}`}
        placeholder="Your Message"
        value={values.message}
        onChange={handleChange}
        onBlur={handleBlur}
        style={{ height: "55px" }}
        aria-label="Your Message"
      />
        {touched.message && errors.message && <div className="invalid-feedback">{errors.message}</div>}
    </div>
    <div className="col-12">
      <button className="btn btn-primary w-100 py-3" type="submit" disabled={isSubmitting}>
        {isSubmitting ? 'Sending...' : 'Send Message'}
        </button>
    </div>
  </div>
</form>

      </div>
      <div className="col-xl-4 col-lg-12 wow slideInUp" data-wow-delay="0.6s">
        <div style={{position: "relative", marginBottom: "20px"}}>
          <h2 style={{fontSize: "18px", fontWeight: "bold", marginBottom: "10px"}}>
            <span style={{marginRight: "10px"}}>
              <i className="fas fa-map-marker-alt" style={{color: "#f00"}}></i>
            </span>
            329/A PUNJAB COLLEGE ROAD, Satellite Town, Sargodha, Punjab 40100
          </h2>
          <iframe className="position-relative rounded w-100 h-100" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d16559.662155007816!2d72.6711!3d32.0791!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391982b14c1f3c45%3A0x4711870a4d4643e4!2s329%2FA%20Punjab%20College%20Rd%2C%20Satellite%20Town%2C%20Sargodha%2C%20Punjab%2040100%2C%20Pakistan!5e0!3m2!1sen!2sus!4v1623395290880!5m2!1sen!2sus" frameborder="0" style={{minHeight: "400px", border: "0"}} allowfullscreen="" aria-hidden="false" tabindex="0" title="329/A PUNJAB COLLEGE ROAD, Satellite Town, Sargodha, Punjab 40100"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>

<br/>
<br/>

    {/* <!-- Contact End --> */}

   <Footer/>
   </>
  )
}
