import React, { useEffect, useState } from "react";
import Navbar from "../Includes/Navbar";
import carousel1 from "../Images/img/harm-reduction.png";
import carousel2 from "../Images/img/carousel-1.png";
import carousel3 from "../Images/img/carousel-2.jpg";
import aboutImage from "../Images/img/about.jpg";
import team1 from "../Images/img/team-1.png";
import team2 from "../Images/img/team-2.png";
import team3 from "../Images/img/team-3.png";
import team4 from "../Images/img/team-4.png";
import team5 from "../Images/img/team-5.png";
import Footer from "../Includes/Footer";
import GeneralService from "../../services/general.service";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import './Home.css'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import azmLogo from '../Images/logo-azm-2.png';
import azmText from '../Images/azm-text.png';
import Appointment from "../Appointment/Appointment";

const signUpSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });
  
export default function Home() {

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
      } = useFormik({
        initialValues: {
          email: '',
        },
        validationSchema: signUpSchema,
        onSubmit: async (values, actions) => {
          try {
            const response = await GeneralService.signUp(values);
            console.log('API response:', response);
            toast.success('Subscribe successful!'); // Show success toast message
            resetForm();
            scrollToTop(); // Scroll to top after successful sign-up
          } catch (error) {
            console.error('API error:', error);
            toast.error('Subscribe failed. Please try again.'); // Show error toast message
          } finally {
            actions.setSubmitting(false);
          }
        },
      });


      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
      };
    
      // Optionally, you can use useEffect to reset scroll position on component mount or update
      useEffect(() => {
        scrollToTop(); // Scroll to top on component mount
      }, []); // Empty dependency array ensures it only runs once on mount
    
    
return (
<>
        <Navbar />

        {/* <!-- Full Screen Search Start --> */}
    <div className="modal fade" id="searchModal" tabindex="-1">
        <div className="modal-dialog modal-fullscreen">
            <div className="modal-content" style={{background: "rgba(9, 30, 62, .7)"}}>
                <div className="modal-header border-0">
                    <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body d-flex align-items-center justify-content-center">
                    <div className="input-group" style={{maxWidth: "600px"}}>
                        <input type="text" className="form-control bg-transparent border-primary p-3" placeholder="Type search keyword"/>
                        <button className="btn btn-primary px-4"><i className="bi bi-search"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Full Screen Search End --> */}


    {/* <!-- Carousel Start --> */}
    <div className="container-fluid p-0">
    <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
        <div className="carousel-inner">
            <div className="carousel-item active">
                <img className="w-100" src={carousel1} alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3 text-center" style={{ maxWidth: "900px" }}>
                        <img className="animated slideInDown img-fluid logo" src={azmLogo} alt="Logo" />
                        <div className="row gx-0">
                            <div className="col-12 d-flex justify-content-center">
                                <img className="animated slideInDown img-fluid text-image" src={azmText} alt="azm-text" />
                            </div>
                        </div>
                        <h1 className="display-4 text-white mb-md-4 animated zoomIn">Addiction & Psychological<br /> Treatment Centre</h1>
                        <div className="row gx-0 mt-4">
                            <div className="col-12 d-flex justify-content-center">
                                <p className="text-white animated zoomIn center-text">علاجگاہ براۓ منشیات و نفسیاتی امراض</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src={carousel2} alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3 text-center" style={{ maxWidth: "900px" }}>
                        <h5 className="text-white text-uppercase mb-3 animated slideInDown">HARM REDUCTION</h5>
                        <h1 className="display-4 text-white mb-md-4 animated zoomIn">Prevent HIV Among Drug Users</h1>
                    </div>
                </div>
            </div>
            <div className="carousel-item">
                <img className="w-100" src={carousel3} alt="Image" />
                <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3 text-center" style={{ maxWidth: "900px" }}>
                        <h5 className="text-white text-uppercase mb-3 animated slideInDown">Keep Your Health Good</h5>
                        <h1 className="display-4 text-white mb-md-4 animated zoomIn">Take The Best Quality Treatment Addiction</h1>
                    </div>
                </div>
            </div>
        </div>
        <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
        </button>
        <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
        </button>
    </div>
</div>

    {/* <!-- Carousel End --> */}

    {/* <!-- Banner Start --> */}
    <div className="container-fluid banner mb-5">
        <div className="container">
            <div className="row gx-0">
                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.1s">
                    <div className="bg-primary d-flex flex-column p-5" style={{height: "300px"}}>
                        <h3 className="text-white mb-3">Opening Hours</h3>
                        <div className="d-flex justify-content-between text-white mb-3">
                            <h5 className="text-white mb-0 mt-2">Mon - Sat</h5>
                            <p className="mb-0 mt-1"><strong>09:00am - 10:00pm</strong></p>
                        </div>
                        <div className="d-flex justify-content-between text-white mb-3">
                            <h5 className="text-white mb-0 mt-2">Sunday - Closed</h5>
                            <p className="mb-0 mt-1"><strong>(Only Emergency)</strong></p>

                        </div>
                        {/* <div className="d-flex justify-content-between text-white mb-3">
                            <p className="mb-0">(Only Emergency)</p>
                        </div> */}
                        {/* <a className="btn btn-light" href="">Appointment</a> */}
                    </div>
                </div>
                {/* <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                    <div className="bg-dark d-flex flex-column p-5" style={{height: "300px"}}>
                        <h3 className="text-white mb-3">Search A Doctor</h3>
                        <div className="date mb-3" id="date" data-target-input="nearest">
                            <input type="text" className="form-control bg-light border-0 datetimepicker-input"
                                placeholder="Appointment Date" data-target="#date" data-toggle="datetimepicker" style={{height: "40px"}} />
                        </div>
                        <select className="form-select bg-light border-0 mb-3" style={{height: "40px"}}>
                            <option selected>Select A Service</option>
                            <option value="1">Service 1</option>
                            <option value="2">Service 2</option>
                            <option value="3">Service 3</option>
                        </select>
                        <a className="btn btn-light" href="">Search Doctor</a>
                    </div>
                </div> */}
                <div className="col-lg-6 wow zoomIn" data-wow-delay="0.6s">
                    <div className="bg-dark d-flex flex-column p-5" style={{height: "300px"}}>
                        <h3 className="text-white mb-3">Make Appointment</h3>
                        <p className="text-white">Ready to start your journey to recovery? <br/>Contact us to schedule your appointment today.</p>
                        <h3 className="text-white mb-3" style={{fontSize: "20px"}}>03009605574</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Banner Start --> */}


    {/* <!-- About Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
    <div className="container">
        <div className="row g-5">
            <div className="col-lg-7 mb-4 mb-lg-0">
                <div className="section-title mb-4">
                    <h5 className="position-relative d-inline-block text-primary text-uppercase">About Us</h5>
                    <h1 className="display-5 mb-0">The World's Best Treatment Clinic That You Can Trust</h1>
                </div>
                <h4 className="text-body fst-italic mb-4">Our dedicated team offers personalized treatment, backed by years of success. Let us help you reclaim your life.</h4>
                <p className="mb-4">Every step of your journey is guided by our certified professionals, ensuring you receive the highest standard of care and support. Trust us to help you take the first step towards a healthier, happier life.</p>
                <div className="row g-3">
                    <div className="col-sm-6 mb-3">
                        <h5 className="mb-0"><i className="fa fa-check-circle text-primary me-3"></i>Award Winning</h5>
                    </div>
                    <div className="col-sm-6 mb-3">
                        <h5 className="mb-0"><i className="fa fa-check-circle text-primary me-3"></i>Professional Staff</h5>
                    </div>
                    <div className="col-sm-6 mb-3">
                        <h5 className="mb-0"><i className="fa fa-check-circle text-primary me-3"></i>24/7 Opened</h5>
                    </div>
                    <div className="col-sm-6 mb-3">
                        <h5 className="mb-0"><i className="fa fa-check-circle text-primary me-3"></i>Fair Prices</h5>
                    </div>
                </div>
            </div>
            <div className="col-lg-5 d-flex justify-content-center align-items-center mb-4 mb-lg-0" style={{ minHeight: "500px" }}>
    <div>
        <img className="img-fluid rounded" src={aboutImage} style={{ objectFit: "cover", maxWidth: "100%" }} />
    </div>
</div>

        </div>
    </div>
</div>
    {/* <!-- About End --> */}

    <Appointment/>

    {/* <!-- Team Start --> */}
    <div className="container-fluid py-5">
        <div className="container">
            <div className="row g-5">
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                    <div className="section-title bg-light rounded h-100 p-5">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Doctors</h5>
                        <h1 className="display-6 mb-4">Meet Our Certified and Highly Experienced Doctors</h1>
                        {/* <a href="appointment.html" className="btn btn-primary py-3 px-5">Appointment</a> */}
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team1} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                        <p className="text-blue mb-0">Associate Professor</p>
                            <h4 className="mb-2">Muhammad Zubair Mukhtar</h4>
                            <span>MSc, PMDCP, DBP (Hypnotherapy)</span>
                            <p className="text-primary mb-0">Managing Director - Azm Rehab Centre</p>
                            <p className="text-primary mb-0">Clinical  Psychologist  & Hypnotherapist</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team2} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. Haris Burki</h4>
                            <span>MCME Psych (USA) Senior Member World Psychiatric Association of Sexual Health</span>
                            <p className="text-primary mb-0">Consultant Sexologist</p>
                            <br/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team3} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. Khan Lati</h4>
                            <span>DHMS MSc Psychology</span>
                            <p className="text-primary mb-0">Group Therapist</p>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>

                
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team5} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">
                                Prof Dr. Muhammad Jawad Shujaat </h4>
                            <span>PhD Psychology</span>
                            <p className="text-primary mb-0">Clinical Psychologist</p>
                            <br/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team4} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Miss. Asma Javed</h4>
                            <span>MSc Psychology</span>
                            <p className="text-primary mb-0">D.F.C</p>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team4} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Miss. Sadia Qarata Al-Ain</h4>
                            <span>MSc (Psychology) Diploma in Clinical Psychology 
                            DBPT (Hypnotherapy)</span>
                            <p className="text-primary mb-0">Psychologist</p>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team5} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. Muhammad Asif Anwar</h4>
                            <span>MBBS (Punjab) - DPM (Punjab)</span>
                            <p className="text-primary mb-0">Consultant Psychiatrist</p>
                            <br/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team5} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. Muhammad Amjad Bajwa</h4>
                            <span>MBBS, RMP</span>
                            <p className="text-primary mb-0">Medical Officer</p>
                            <br/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team5} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. Moazzam Fawad</h4>
                            <span>MBBS, FCPS</span>
                            <p className="text-primary mb-0">Medical specialist<br/>
                            Cancer, Liver, Kidney, Heart And Diabetes</p>
                            <br/>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team5} alt=""/>
                            {/* <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                                <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            </div> */}
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. Mirza Raees</h4>
                            <span>MBBS, RMP</span>
                            <p className="text-primary mb-0">Medical Officer</p>
                            <br/>
                            <br/>
                        </div>
                    </div>
                </div>
                {/* <div className="col-lg-4 wow slideInUp" data-wow-delay="0.1s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team3} alt=""/>
                            // <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            // </div>
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. John Doe</h4>
                            <p className="text-primary mb-0">Implant Surgeon</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team4} alt=""/>
                            // <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            // </div>
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. John Doe</h4>
                            <p className="text-primary mb-0">Implant Surgeon</p>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                    <div className="team-item">
                        <div className="position-relative rounded-top" style={{zIndex: "1"}}>
                            <img className="img-fluid rounded-top w-100" src={team5} alt=""/>
                            // <div className="position-absolute top-100 start-50 translate-middle bg-light rounded p-2 d-flex">
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-twitter fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
                            //     <a className="btn btn-primary btn-square m-1" href="#"><i className="fab fa-instagram fw-normal"></i></a>
                            // </div>
                        </div>
                        <div className="team-text position-relative bg-light text-center rounded-bottom p-4 pt-5">
                            <h4 className="mb-2">Dr. John Doe</h4>
                            <p className="text-primary mb-0">Implant Surgeon</p>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    </div>
    {/* <!-- Team End --> */}

    {/* <!-- Newsletter Start --> */}
    <div className="container-fluid position-relative pt-5 wow fadeInUp" data-wow-delay="0.1s" style={{ zIndex: '1' }}>
       <ToastContainer/>
        <div className="container">
          <div className="bg-primary p-5">
            <form className="mx-auto" style={{ maxWidth: '600px' }} onSubmit={handleSubmit}>
              <div className="form-floating mb-4">
                <input
                  type="text"
                  id="email"
                  name="email"
                  className={`form-control ${touched.email && errors.email ? 'is-invalid' : ''}`}
                  placeholder="Your Email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                <label htmlFor="email">Email</label>
                {touched.email && errors.email && <div className="invalid-feedback">{errors.email}</div>}
              </div>
              <button type="submit" className="btn btn-dark px-4" disabled={isSubmitting}>
                {isSubmitting ? 'Subscribing...' : 'Subscribe'}
              </button>
            </form>
          </div>
        </div>
      </div>
    {/* <!-- Newsletter End --> */}
    

<Footer/>
</>
);
}
