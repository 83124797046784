import React from 'react'
import azmLogo from '../Images/logo-azm-2.png';
import { Link } from 'react-router-dom';
export default function Navbar() {
  return (
    <>
    {/* // <!-- Topbar Start --> */}
    <div class="container-fluid bg-light ps-5 pe-0 d-none d-lg-block">
        <div class="row gx-0">
            <div class="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                <div class="d-inline-flex align-items-center">
                    <small class="py-2"><i class="far fa-clock text-primary me-2"></i>Opening Hours: Mon - Sat : 09.00 am - 10.00 pm, Sunday Closed (Only Emergency)</small>
                </div>
            </div>
            <div class="col-md-6 text-center text-lg-end">
                <div class="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
                    <div class="me-3 pe-3 border-end py-2">
                        <p class="m-0"><i class="fa fa-envelope-open me-2"></i>zubairmukhtar14@gmail.com</p>
                    </div>
                    <div class="py-2">
                        <p class="m-0"><i class="fa fa-phone-alt me-2"></i>03009605574</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* // <!-- Topbar End --> */}

    {/* // <!-- Navbar Start --> */}
    <nav class="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
    <Link to="/" className="navbar-brand p-0">
  <div className="d-flex align-items-center">
    <img src={azmLogo} alt="Logo" className="me-2" style={{ height: '100px' }} />
    <h1 className="m-0 text-primary d-none d-sm-block">Azm Rehab Centre</h1>
  </div>
</Link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarCollapse">
            <div class="navbar-nav ms-auto py-0">
                <Link to="/" class="nav-item nav-link active">Home</Link>
                {/* <Link to="about.html" class="nav-item nav-link">About</Link> */}
                <Link to="/service" class="nav-item nav-link">Service</Link>
                <Link to="/staff-team" class="nav-item nav-link">Staff</Link>
                {/* <div class="nav-item dropdown">
                    <Link to="#" class="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</Link>
                    <div class="dropdown-menu m-0">
                        <Link to="/price" class="dropdown-item">Pricing Plan</Link>
                        <Link to="/team" class="dropdown-item">Our Dentist</Link>
                        <Link to="/testimonial" class="dropdown-item">Testimonial</Link>
                        <Link to="/appointment" class="dropdown-item">Appointment</Link>
                    </div>
                </div> */}
                <Link to="/contact-us" class="nav-item nav-link">Contact</Link>
            </div>
            {/* <button type="button" class="btn text-dark" data-bs-toggle="modal" data-bs-target="#searchModal"><i class="fa fa-search"></i></button> */}
            {/* <Link to="appointment.html" class="btn btn-primary py-2 px-4 ms-3">Appointment</Link> */}
        </div>
    </nav>
    {/* // <!-- Navbar End --> */}
    </>
  )
}
