import React, { useEffect, useState } from "react";
import azmLogo from '../Images/logo-azm-2.png';

export default function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    setCurrentYear(new Date().getFullYear());
  }, []);
  return (
    <>
  {/* <!-- Footer Start --> */}
    <div className="container-fluid bg-dark text-light py-5 wow fadeInUp" data-wow-delay="0.3s" style={{marginTop: "-75px"}}>
        <div className="container pt-5">
            <div className="row g-5 pt-4">
                <div className="col-lg-3 col-md-6">
                <img className="img-fluid logo" src={azmLogo} alt="Logo" />
                <h3 className="text-white mb-4">WE CARE<br/> ALLAH CURES</h3>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Popular Links</h3>
                    <div className="d-flex flex-column justify-content-start">
                        <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Home</a>
                        <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>About Us</a>
                        <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Our Services</a>
                        <a className="text-light mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a>
                        <a className="text-light" href="#"><i className="bi bi-arrow-right text-primary me-2"></i>Contact Us</a>
                    </div>
                </div>
                <div className="col-lg-3 col-md-6">
                    <h3 className="text-white mb-4">Get In Touch</h3>
                    <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2"></i>329/A PUNJAB COLLEGE ROAD, Satellite Town, Sargodha, Punjab 40100</p>
                    <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2"></i>zubairmukhtar14@gmail.com</p>
                    <p className="mb-0"><i className="bi bi-telephone text-primary me-2"></i>03009605574</p>
                </div>
                <div className="col-lg-3 col-md-6 d-flex justify-content-center mb-4 mb-md-0">
    <div className="text-center">
        <h3 className="text-white mb-4">Follow Us</h3>
        <div className="d-flex justify-content-center">
            <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i className="fab fa-twitter fw-normal"></i></a>
            <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i className="fab fa-facebook-f fw-normal"></i></a>
            <a className="btn btn-lg btn-primary btn-lg-square rounded me-2" href="#"><i className="fab fa-linkedin-in fw-normal"></i></a>
            <a className="btn btn-lg btn-primary btn-lg-square rounded" href="#"><i className="fab fa-instagram fw-normal"></i></a>
        </div>
    </div>
</div>

            </div>
        </div>
    </div>
    <div className="container-fluid text-light py-4" style={{background: "#051225"}}>
        <div className="container">
            <div className="row g-0">
                {/* <div className="col-md-12 text-center text-md-start"> */}
                    <p className="mb-md-0">&copy; <a className="text-white border-bottom" href="#">Azm Rehab Centre</a>. All Rights Reserved.</p>
                {/* </div> */}
                {/* <div className="col-md-6 text-center text-md-end">
                    <p className="mb-0">Designed by <a className="text-white border-bottom" href="https://htmlcodex.com">HTML Codex</a></p>
                </div> */}
            </div>
        </div>
    </div>
    {/* <!-- Footer End --> */}



    </>
  );
}
