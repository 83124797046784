import axios from "axios";

// const API_URL = process.env.REACT_APP_API_Link;
const API_URL = "https://api.azmrehabcentre.pk";


const signUp = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`${API_URL}/subscribe`, { email: values.email }, { headers });
      return response.data; // Return the response data
    } catch (error) {
      throw new Error('Failed to sign up'); // Throw an error to be caught in onSubmit
    }
  };
  const contactMessage = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`${API_URL}/contact-message`, values, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Failed to send message');
    }
  };
  const makeAppointment = async (values) => {
    try {
      const headers = {
        'Content-Type': 'application/json',
      };
      const response = await axios.post(`${API_URL}/make-appointment`, values, { headers });
      return response.data;
    } catch (error) {
      throw new Error('Failed to make Appointment');
    }
  };

const GeneralService = {
    signUp,
    contactMessage,
    makeAppointment
};

export default GeneralService;
