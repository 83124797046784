import React from 'react'
import Navbar from '../Includes/Navbar'
import Footer from '../Includes/Footer'
import beforeImage from "../Images/img/before.jpg";
import afterImage from "../Images/img/s-image.png";
import service1 from "../Images/img/s1.png";
import service2 from "../Images/img/s2.png";
import service3 from "../Images/img/s3.jpeg";
import service4 from "../Images/img/s4.png";
import { Link } from 'react-router-dom/dist';

export default function Service() {
  return (
   <>
   <Navbar/>
   {/* <!-- Hero Start --> */}
    <div className="container-fluid bg-primary py-5 hero-header mb-5">
        <div className="row py-3">
            <div className="col-12 text-center">
                <h1 className="display-3 text-white animated zoomIn">Services</h1>
                <Link to="/" className="h4 text-white">Home</Link>
                <span className="text-white px-2">∕</span>
                <a href="#" className="h4 text-white disabled" aria-disabled="true" style={{ pointerEvents: "none", textDecoration: "none", color: "gray", opacity: "0.5" }}>Services</a>

            </div>
        </div>
    </div>
    {/* <!-- Hero End --> */}


    {/* <!-- Service Start --> */}
    <div className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row g-5 mb-5">
                <div className="col-lg-5 wow zoomIn" data-wow-delay="0.3s" style={{minHeight: "400px"}}>
                    <div className="twentytwenty-container position-relative h-100 rounded overflow-hidden">
                        <img className="position-absolute w-100 h-100" src={beforeImage} style={{objectTit: "cover"}}/>
                        <img className="position-absolute w-100 h-100" src={afterImage} style={{objectTit: "cover"}}/>
                    </div>
                </div>
                <div className="col-lg-7">
                    <div className="section-title mb-5">
                        <h5 className="position-relative d-inline-block text-primary text-uppercase">Our Services</h5>
                        <h1 className="display-5 mb-0">We Offer The Best Quality Treatment Services</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.6s">
                            <div className="rounded-top overflow-hidden">
                                <img className="img-fluid" src={service1} alt=""/>
                            </div>
                            <div className="position-relative bg-light rounded-bottom text-center p-4">
                                <h5 className="m-0">Drug Abuse Treatment</h5>
                            </div>
                        </div>
                        <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.9s">
                            <div className="rounded-top overflow-hidden">
                                <img className="img-fluid" src={service2} alt=""/>
                            </div>
                            <div className="position-relative bg-light rounded-bottom text-center p-4">
                                <h5 className="m-0">Alcohol Treatment</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row g-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="col-lg-7">
                    <div className="row g-5">
                        <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.3s">
                            <div className="rounded-top overflow-hidden">
                                <img className="img-fluid" src={service3} alt=""/>
                            </div>
                            <div className="position-relative bg-light rounded-bottom text-center p-4">
                                <h5 className="m-0">Ice Addicts Treatment</h5>
                            </div>
                        </div>
                        <div className="col-md-6 service-item wow zoomIn" data-wow-delay="0.6s">
                            <div className="rounded-top overflow-hidden">
                                <img className="img-fluid" src={service4} alt=""/>
                            </div>
                            <div className="position-relative bg-light rounded-bottom text-center p-4">
                                <h5 className="m-0">Mentaly Health Treatment</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 service-item wow zoomIn" data-wow-delay="0.9s">
                    <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-4">
                        <h3 className="text-white mb-3">Make Appointment</h3>
                        <p className="text-white mb-3">Ready to start your journey to recovery? <br/>Contact us to schedule your appointment today.</p>
                        <h2 className="text-white mb-0">03009605574</h2>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Service End --> */}
    
   <Footer/>
   </>
  )
}
