import React, { useEffect } from 'react'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import GeneralService from '../../services/general.service';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const isValidTime = (time) => {
  const [hour, minute] = time.split(':');
  const hourInt = parseInt(hour, 10);
  const minuteInt = parseInt(minute, 10);
  
  const timeValue = hourInt + minuteInt / 60;
  return timeValue >= 10 && timeValue <= 22;
};

const appointmentSchema = Yup.object({
    service: Yup.string().required('Required'),
    doctor: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    email: Yup.string().email('Invalid email address').required('Required'),
    date: Yup.date().required('Required'),
    // time: Yup.string().required('Required'),
    time: Yup.string()
    .required('Required')
    .test('is-valid-time', 'Time must be between 10:00 AM and 10:00 PM', (value) => {
      return isValidTime(value);
    })
  });
export default function Appointment() {

    const {
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
      } = useFormik({
        initialValues: {
          service: '',
          doctor: '',
          name: '',
          email: '',
          date: '',
          time: '',
        },
        validationSchema:appointmentSchema ,
        onSubmit: async (values, { setSubmitting }) => {
          try {
          const response = await GeneralService.makeAppointment(values);
            console.log(response);
          toast.success('Appointment successful!'); 
            resetForm();
          scrollToTop(); 
          } catch (error) {
            console.error(error.message);
          toast.error('Appointment make failed. Please try again.');
          } finally {
            setSubmitting(false);
          }
        },
      });

      const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };
    
      useEffect(() => {
        scrollToTop(); 
      }, []); 
    
  return (
    <>
   <ToastContainer/>

        {/* <!-- Appointment Start --> */}
        <div className="container-fluid bg-primary bg-appointment my-5 wow fadeInUp" data-wow-delay="0.1s">
        <div className="container">
            <div className="row gx-5">
                <div className="col-lg-6 py-5">
                    <div className="py-5">
                        <h1 className="display-5 text-white mb-4">We Are A Certified and Award Winning Treatment Clinic You Can Trust</h1>
                        <p className="text-white mb-0">Our clinic is recognized for its excellence in providing top-notch treatment and care. With certifications and awards from leading healthcare organizations, we are dedicated to delivering the best possible outcomes for our patients.</p>
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="appointment-form h-100 d-flex flex-column justify-content-center text-center p-5 wow zoomIn" data-wow-delay="0.6s">
                        <h1 className="text-white mb-4">Make Appointment</h1>
                        <form onSubmit={handleSubmit}>
      <div className="row g-3">
        <div className="col-12 col-sm-6">
  <label htmlFor="service" className="form-label" style={{color: "#fff"}}>Appointment Service</label>
          <select
            name="service"
            className={`form-select bg-light border-0 ${touched.service && errors.service ? 'is-invalid' : ''}`}
            style={{ height: '55px' }}
            value={values.service}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select A Service</option>
            <option value="Drug Abuse Treatment">Drug Abuse Treatment</option>
            <option value="Alcohol Treatment">Alcohol Treatment</option>
            <option value="Ice Addicts Treatment">Ice Addicts Treatment</option>
            <option value="">Mentaly Health Treatment</option>
          </select>
          {touched.service && errors.service && <div className="invalid-feedback">{errors.service}</div>}
        </div>
        <div className="col-12 col-sm-6">
  <label htmlFor="doctor" className="form-label" style={{color: "#fff"}}>Appointment Doctor</label>
          <select
            name="doctor"
            className={`form-select bg-light border-0 ${touched.doctor && errors.doctor ? 'is-invalid' : ''}`}
            style={{ height: '55px' }}
            value={values.doctor}
            onChange={handleChange}
            onBlur={handleBlur}
          >
            <option value="">Select Doctor</option>
            <option value="Muhammad Zubair Mukhtar">Muhammad Zubair Mukhtar</option>
            <option value="Dr. Haris Burki">Dr. Haris Burki</option>
          </select>
          {touched.doctor && errors.doctor && <div className="invalid-feedback">{errors.doctor}</div>}
        </div>
        <div className="col-12 col-sm-6">
  <label htmlFor="name" className="form-label" style={{color: "#fff"}}>Patient Name</label>
          <input
            type="text"
            name="name"
            className={`form-control bg-light border-0 ${touched.name && errors.name ? 'is-invalid' : ''}`}
            placeholder="Your Name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ height: '55px' }}
          />
          {touched.name && errors.name && <div className="invalid-feedback">{errors.name}</div>}
        </div>
        <div className="col-12 col-sm-6">
  <label htmlFor="email" className="form-label" style={{color: "#fff"}}>Email Adddress</label>

          <input
            type="email"
            name="email"
            className={`form-control bg-light border-0 ${touched.email && errors.email ? 'is-invalid' : ''}`}
            placeholder="Your Email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ height: '55px' }}
          />
          {touched.email && errors.email && <div className="invalid-feedback">{errors.email}</div>}
        </div>
        <div className="col-12 col-sm-6">
  <label htmlFor="date" className="form-label" style={{color: "#fff"}}>Appointment Date</label>

          <input
            type="date"
            name="date"
            className={`form-control bg-light border-0 ${touched.date && errors.date ? 'is-invalid' : ''}`}
            placeholder="Appointment Date"
            value={values.date}
            onChange={handleChange}
            onBlur={handleBlur}
            style={{ height: '55px' }}
          />
          {touched.date && errors.date && <div className="invalid-feedback">{errors.date}</div>}
        </div>
        <div className="col-12 col-sm-6">
  <label htmlFor="time" className="form-label" style={{color: "#fff"}}>Appointment Time</label>
  <input
    type="time"
    name="time"
    id="time"
    className={`form-control bg-light border-0 ${touched.time && errors.time ? 'is-invalid' : ''}`}
    value={values.time}
    onChange={handleChange}
    onBlur={handleBlur}
    style={{ height: '55px' }}
  />
  {touched.time && errors.time && <div className="invalid-feedback">{errors.time}</div>}
</div>

        <div className="col-12">
          <button className="btn btn-dark w-100 py-3" type="submit" disabled={isSubmitting}>
            {isSubmitting ? 'Submitting...' : 'Make Appointment'}
          </button>
        </div>
      </div>
    </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {/* <!-- Appointment End --> */}
    </>

  )
}
