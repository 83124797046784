import logo from './logo.svg';
import './App.css';
import Home from './Components/Home/Home';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import azmLogo from './Components/Images/logo-azm-2.png';
import { useEffect, useState } from 'react';
import Service from './Components/Service/Service';
import ContactUs from './Components/ContactUs/ContactUs';
import Staff from './Components/Staff/Staff';

function App() {
  const [showSplash, setShowSplash] = useState(true);

  useEffect(() => {
    // Simulate splash screen display for 3 seconds
    const timer = setTimeout(() => {
      setShowSplash(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className={showSplash ? 'splash-screen' : 'App'}>
    {showSplash && (
      <div className="splash-content">
        <img src={azmLogo} className='logo-azm2' alt="Azm Rehab Centre Logo"/>
        <div className="loader"></div>
      </div>
    )}

    {!showSplash && (
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="/service" element={<Service/>} />
            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="/staff-team" element={<Staff/>} />
          </Routes>
        </BrowserRouter>
        <FloatingWhatsApp
          phoneNumber="+923009605574"
          accountName="Azm Rehab Centre"
          chatMessage="Hi! How can we help you?"
          statusMessage=""
          placeholder="Type a message"
          avatar="/favicon.ico"
          allowEsc
          allowClickAway
          notification
          notificationSound
        />
      </div>
    )}
  </div>
  );
}

export default App;
